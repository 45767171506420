import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import cn from 'classnames';

import { IconButton } from 'components';
import { useResponsive } from 'hooks/useResponsive';

import styles from './SlideShow.module.scss';

export interface SlideShowProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children nodes */
  children: React.ReactNode | Array<React.ReactNode>;
  /** how many slider to be shown at once */
  slidesPerRow?: number;
  /** space between to slides */
  gutter?: number;
  /** partial slide space 0 <= x < 1 */
  partialSlide?: number;
  topAlign?: boolean;
  width?: number;
  maxWidth?: string;
  isSocietyProfile?: boolean;
  navigationClassName?: string;
}

export const SlideShow: React.FC<SlideShowProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    children,
    slidesPerRow = 1,
    gutter = 0,
    partialSlide = 0.25,
    topAlign,
    width,
    maxWidth,
    isSocietyProfile,
    navigationClassName,
  }: SlideShowProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(0);
    const slidesRef = useRef<HTMLDivElement | null>(null);
    const slidesElement = slidesRef.current;

    const screens = useResponsive();

    useEffect(() => {
      setLength((children as React.ReactNode[]).length);
      //Implement better logic after MVP release
      if (currentIndex && currentIndex > length - slidesPerRow)
        setCurrentIndex(length - slidesPerRow - 1);
    }, [children]);

    const next = useCallback(() => {
      if (currentIndex < length - 1) {
        setCurrentIndex((prevState) => prevState + 1);
      }
    }, [currentIndex, length]);

    const prev = useCallback(() => {
      if (currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1);
      }
    }, [currentIndex]);

    const shouldRenderNavigation = useMemo(() => {
      return (
        length > slidesPerRow || (currentIndex > 0 && length === slidesPerRow)
      );
    }, [length, slidesPerRow]);

    const slideWidth: number = useMemo(() => {
      if (slidesElement !== null) {
        return (
          (slidesElement.clientWidth - slidesPerRow * gutter) /
            (slidesPerRow + partialSlide) +
          gutter
        );
      } else {
        return 0;
      }
    }, [slidesPerRow, slidesElement, gutter]);

    const contentClassNames = cn(styles.SlideShow, className);

    const slides = useMemo(() => {
      const widthWithGutter = width ? width + gutter : slideWidth;
      const translateValue =
        slidesPerRow + currentIndex === length
          ? (currentIndex - 1) * widthWithGutter +
            (width || slideWidth - gutter) * (1 - partialSlide)
          : currentIndex * widthWithGutter;
      const translateX = width
        ? currentIndex * widthWithGutter
        : translateValue;

      return (
        <div
          className={styles.SlideShowSlides}
          style={{ transform: `translateX(-${translateX}px)` }}
          ref={slidesRef}
        >
          {React.Children.map(children, (child, index) => {
            return (
              <div
                key={index}
                className={cn(styles.SlideShowSlideContainer, {
                  [styles.topAlign]: topAlign,
                })}
                style={{
                  width: width ? `${width}px` : slideWidth - gutter,
                  marginRight: index === length - 1 ? 0 : gutter,
                  maxWidth,
                }}
              >
                {child}
              </div>
            );
          })}
        </div>
      );
    }, [currentIndex, slideWidth, length, gutter, children]);

    const arrowContainerWidth = (slideWidth - gutter) * partialSlide;

    const leftArrow = useMemo(() => {
      if (!shouldRenderNavigation || currentIndex === 0) return null;
      return (
        <div
          className={cn(
            styles.SlideShowNavigation,
            styles.SlideShowNavigationLeft,
            navigationClassName
          )}
          style={{
            width: !isSocietyProfile
              ? arrowContainerWidth > 0
                ? arrowContainerWidth
                : undefined
              : undefined,
          }}
        >
          <IconButton
            iconName="chevron-left"
            size={screens.sm ? 'large' : 'xlarge'}
            onClick={prev}
            className={styles.SlideShowNavigationButton}
            type="button"
          />
        </div>
      );
    }, [shouldRenderNavigation, currentIndex, prev, arrowContainerWidth]);

    const rightArrow = useMemo(() => {
      if (!shouldRenderNavigation || currentIndex === length - slidesPerRow)
        return null;
      return (
        <div
          className={cn(
            styles.SlideShowNavigation,
            styles.SlideShowNavigationRight,
            navigationClassName
          )}
          style={{
            width: !isSocietyProfile
              ? arrowContainerWidth > 0
                ? arrowContainerWidth
                : undefined
              : undefined,
          }}
        >
          <IconButton
            size={screens.sm ? 'large' : 'xlarge'}
            iconName="chevron-right"
            onClick={next}
            className={styles.SlideShowNavigationButton}
            type="button"
          />
        </div>
      );
    }, [shouldRenderNavigation, currentIndex, length, arrowContainerWidth]);

    return (
      <section
        className={contentClassNames}
        style={style}
        data-testid="SlideShow"
      >
        {leftArrow}
        {shouldRenderNavigation ? (
          <div className={styles.SlideShowWrapper}>{slides}</div>
        ) : (
          <>{slides}</>
        )}
        {rightArrow}
      </section>
    );
  }
);

SlideShow.displayName = 'SlideShow';
