import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { useNotification } from 'hooks/useNotification';
import { get, patch, post, remove } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import {
  parseErrorResponse,
  parseResponse,
  parseArrayResponse,
} from 'lib/utils/parser';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { RoomAttributes, RoomResponse } from 'lib/models/room';
import {
  ParticipantSResponse,
  ParticipantAttributes,
} from 'lib/models/participant';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';

export interface ReturnType {
  createGroupChat: (event_id: string) => Promise<RoomAttributes | void>;
  blockParticipant: (roomId: string, userId: string) => Promise<void>;
  muteRoomOrParticipant: (roomId: string, userId?: string) => Promise<void>;
  unMuteRoomOrParticipant: (roomId: string, userId?: string) => Promise<void>;
  unblockParticipant: (roomId: string, userId: string) => Promise<void>;
  getRoomParticipants: (
    id: string
  ) => Promise<Array<ParticipantAttributes> | undefined>;
  deleteRoom: (id: string) => Promise<void>;
  createMessageChat: (id: string) => Promise<void>;
  blockChat: (id: string) => Promise<void>;
  unblockChat: (id: string) => Promise<void>;
  onContactClick: (
    participable_id: string,
    participable_type: string
  ) => Promise<void>;
  isLoading: boolean;
  sendSponsorshipOfferMessage: ({
    student_society_id,
    message,
    sponsorship_plan_id,
  }: {
    student_society_id: string;
    message: string;
    sponsorship_plan_id: string | null;
  }) => Promise<boolean | undefined>;
}

export const useChatRoom = (): ReturnType => {
  const { headers } = useRecoilValue(httpHeadersState);
  const notificationInstance = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { isManager, isRecruiter } = useAuth();

  const roomApi = isManager
    ? API_ROUTES.STUDENT_SOCIETY_ROOM
    : API_ROUTES.COMPANY_ROOM;

  const createGroupChat = async (eventId: string) => {
    const body = {
      room: {
        created_for_id: eventId,
        created_for_type: 'Touchpoint',
        for_all: true,
      },
    };
    try {
      const roomResponse = await post<RoomResponse>(roomApi, body, headers);
      const room = parseResponse<RoomAttributes>(roomResponse);
      return room;
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not created the group chat',
        message,
      });
    }
  };

  const createMessageChat = async (candidate_id: string) => {
    const body = {
      room: {
        participants_attributes: [
          {
            participable_id: candidate_id,
            participable_type: 'Candidate',
          },
        ],
      },
    };
    try {
      const roomResponse = await post<RoomResponse>(
        API_ROUTES.ROOMS,
        body,
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        router.push(
          {
            pathname: PAGE_ROUTES.MESSAGES,
            query: { roomId: room.id },
          },
          PAGE_ROUTES.MESSAGES
        );
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const deleteRoom = async (id: string) => {
    try {
      const roomResponse = await remove<RoomResponse>(
        `${roomApi}/${id}`,
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        notificationInstance.success({
          title: 'Success',
          message: 'Conversation deleted successfully',
        });
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const blockParticipant = async (roomId: string, userId: string) => {
    const body = {
      participable_id: userId,
    };
    try {
      const roomResponse = await post<RoomResponse>(
        `${roomApi}/${roomId}/block_participant`,
        body,
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        notificationInstance.success({
          title: 'Success',
          message: 'Blocked participant successfully',
        });
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const muteRoomOrParticipant = async (roomId: string, userId?: string) => {
    try {
      const response = await patch<{ alert: string }>(
        `${API_ROUTES.ROOMS}/${roomId}/mute`,
        {
          ...(userId && {
            participable_id: userId,
          }),
        },
        headers
      );
      notificationInstance.success({
        title: 'Success',
        message: response.alert,
      });
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };
  const unMuteRoomOrParticipant = async (roomId: string, userId?: string) => {
    try {
      const response = await patch<{ alert: string }>(
        `${API_ROUTES.ROOMS}/${roomId}/unmute`,
        {
          ...(userId && {
            participable_id: userId,
          }),
        },
        headers
      );

      notificationInstance.success({
        title: 'Success',
        message: response.alert,
      });
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const unblockParticipant = async (roomId: string, userId: string) => {
    const body = {
      participable_id: userId,
    };
    try {
      const roomResponse = await post<RoomResponse>(
        `${roomApi}/${roomId}/unblock_participant`,
        body,
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        notificationInstance.success({
          title: 'Success',
          message: 'Unblocked participant successfully',
        });
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const blockChat = async (roomId: string) => {
    try {
      const roomResponse = await post<RoomResponse>(
        `${roomApi}/${roomId}/block`,
        {},
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        notificationInstance.success({
          title: 'Success',
          message: 'Paused conversation successfully',
        });
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const unblockChat = async (roomId: string) => {
    try {
      const roomResponse = await post<RoomResponse>(
        `${roomApi}/${roomId}/unblock`,
        {},
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        notificationInstance.success({
          title: 'Success',
          message: 'Resumed conversation successfully',
        });
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: 'Could not contact the applicant!',
        message,
      });
    }
  };

  const getRoomParticipants = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await get<ParticipantSResponse>(
        `${roomApi}/${id}/participants`,
        headers
      );
      setIsLoading(false);
      return parseArrayResponse(response);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onContactClick = async (
    participable_id: string,
    participable_type: string
  ) => {
    const body = {
      room: {
        participants_attributes: [
          {
            participable_id,
            participable_type,
          },
        ],
      },
    };
    try {
      const roomResponse = await post<RoomResponse>(
        API_ROUTES.ROOMS,
        body,
        headers
      );
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        router.push(
          {
            pathname: PAGE_ROUTES.MESSAGES,
            query: { roomId: room.id },
          },
          PAGE_ROUTES.MESSAGES
        );
      }
    } catch (error) {
      const message = parseErrorResponse(error);
      notificationInstance.error({
        title: `Could not contact the ${
          participable_type === 'StudentSociety'
            ? 'Student Society'
            : participable_type
        }!`,
        message,
      });
    }
  };

  const sendSponsorshipOfferMessage = async ({
    student_society_id,
    message,
    sponsorship_plan_id,
  }: {
    student_society_id: string;
    message: string;
    sponsorship_plan_id: string | null;
  }) => {
    if (sponsorship_plan_id) {
      const pathname = isRecruiter
        ? `/api/v1/company/sponsorship_invitations`
        : '';

      try {
        const body = {
          sponsorship_invitation: {
            student_society_id,
            cover_letters: message,
            sponsorship_plan_id,
          },
        };
        const response = await apiInstance.post(pathname, body);
        if (response) {
          notificationInstance.success({
            title: 'Offer sent!',
            message: '',
          });
          return true;
        }
      } catch (error) {
        notificationInstance.error(error as Error);
      }
    } else {
      if (isRecruiter) {
        const pathname = `/api/v1/company/messages/sponsorship_message`;
        try {
          const body = { message: { value: message }, student_society_id };
          const response = await apiInstance.post(pathname, body);
          if (response) {
            notificationInstance.success({
              title: 'Message sent!',
              message: '',
            });
            return true;
          }
        } catch (error) {
          notificationInstance.error(error as Error);
        }
      } else {
        return await messageStudentSociety(student_society_id, message);
      }
    }
  };

  const messageStudentSociety = async (
    participable_id: string,
    message: string
  ) => {
    try {
      setIsLoading(true);
      const path = API_ROUTES.STUDENT_SOCIETY_ROOM;
      const body = {
        room: {
          participants_attributes: [
            {
              participable_id,
              participable_type: 'StudentSociety',
            },
          ],
        },
      };
      const roomData = await apiInstance.post(path, body);
      const id = roomData.data.data.id;
      const messageBody = {
        message: {
          value: message,
        },
      };
      const response = await apiInstance.post(
        `${API_ROUTES.ROOMS}/${id}/messages`,
        messageBody
      );
      if (response) {
        notificationInstance.success({
          title: 'Message sent!',
          message: '',
        });
        return true;
      }
    } catch (error) {
      notificationInstance.error(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createGroupChat,
    blockParticipant,
    unblockParticipant,
    getRoomParticipants,
    deleteRoom,
    createMessageChat,
    blockChat,
    unblockChat,
    isLoading,
    muteRoomOrParticipant,
    unMuteRoomOrParticipant,
    onContactClick,
    sendSponsorshipOfferMessage,
  };
};
