import { AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { useNotification } from 'hooks/useNotification';
import { API_ROUTES } from 'lib/api-routes';

import {
  CompanyAttributes,
  CompanyDashboardAttributes,
  CompanyRequest,
  CompanyResponse,
} from 'lib/models/company';
import { InsightArticleRequest, InsightAttributes } from 'lib/models/insight';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import {
  OpportunitiesResponse,
  OpportunityAttributes,
} from 'lib/models/opportunity';
import { CustomLinksAttributes } from 'lib/models/custom-links';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';

export interface CompanyProfileReturnType {
  company: CompanyAttributes;
  companyInsights: Array<InsightArticleRequest>;
  companyBenefitStat: CompanyRequest['benefit_stats_attributes'];
  companyCultuteStat: CompanyRequest['culture_stats_attributes'];
  onCompanyUpdate: (
    data: CompanyRequest
  ) => Promise<CompanyResponse | undefined>;
  companyDashboard: CompanyDashboardAttributes | undefined;
  mutateCompanyDashboard: () => void;
  similarOpportunities: Array<OpportunityAttributes>;
  similarEvents: Array<OpportunityAttributes>;
  isLoading: boolean;
  companyCustomLinks: Array<CustomLinksAttributes>;
}

export const useCompanyProfile = (): CompanyProfileReturnType => {
  const notificationInstance = useNotification();
  const { isRecruiter } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: companyResponse, mutate: mutateCompanyResponse } = useSWR<
    AxiosResponse<CompanyResponse>
  >(isRecruiter ? API_ROUTES.COMPANY_PROFILE : null, apiInstance.get, {
    revalidateOnFocus: false,
  });

  const company = parseResponse<CompanyAttributes>(companyResponse?.data);

  const { data: companyDashboardData, mutate: mutateCompanyDashboard } = useSWR<
    AxiosResponse<CompanyDashboardAttributes>
  >(isRecruiter ? API_ROUTES.COMPANY_DASHBOARD : null, apiInstance.get, {
    revalidateOnFocus: false,
  });
  const companyDashboard = companyDashboardData?.data;
  const { total_count_map } = companyDashboard || {};

  const {
    Job: total_job_count = 0,
    Internship: total_internship_count = 0,
    Event: total_event_count = 0,
  } = total_count_map || {};

  const similarOpportunitiesPathname = `${API_ROUTES.COMPANY_TOUCHPOINTS}/similar?with_details=true&page=1&per_page=10&touchpointable_types[]=Job&touchpointable_types[]=Internship`;

  const { data: similarOpportunitiesResponse } = useSWR<
    AxiosResponse<OpportunitiesResponse>
  >(
    total_job_count === 0 && total_internship_count === 0 && isRecruiter
      ? similarOpportunitiesPathname
      : null,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );
  const similarOpportunities = parseArrayResponse<OpportunityAttributes>(
    similarOpportunitiesResponse?.data
  );

  const similarEventsPathname = `${API_ROUTES.COMPANY_TOUCHPOINTS}/similar?with_details=true&page=1&per_page=10&touchpointable_types[]=Event`;

  const { data: similarEventsResponse } = useSWR<
    AxiosResponse<OpportunitiesResponse>
  >(
    total_event_count === 0 && isRecruiter ? similarEventsPathname : null,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );
  const similarEvents = parseArrayResponse<OpportunityAttributes>(
    similarEventsResponse?.data
  );

  const companyCultuteStat = useMemo(() => {
    const list = parseArrayResponse<
      CompanyRequest['culture_stats_attributes'][0]
    >(company?.culture_stats);
    const arr = list.map(({ id, title, value }) => ({ id, title, value }));
    return arr;
  }, [company]);

  const companyBenefitStat = useMemo(() => {
    const list = parseArrayResponse<
      CompanyRequest['benefit_stats_attributes'][0]
    >(company?.benefit_stats);
    const arr = list.map(({ id, title, value }) => ({ id, title, value }));
    return arr;
  }, [company]);

  const companyInsights = useMemo(() => {
    const insights = parseArrayResponse<InsightAttributes>(company?.insights);
    return insights.map((insight) => {
      return {
        title: insight.title,
        id: insight.id,
        url: insight.url,
      };
    });
  }, [company]);

  const onCompanyUpdate = async (
    data: CompanyRequest
  ): Promise<CompanyResponse | undefined> => {
    const {
      logo,
      banner,
      culture_stats_attributes: cultureStats,
      benefit_stats_attributes: benefitStats,
      insights_attributes: insights,
      ...rest
    } = data;
    const icon = (logo?.length > 0 && logo[0]) || undefined;
    const bannerFile = (banner?.length > 0 && banner[0]) || undefined;
    // adding position attribute according to index number to order the stats
    const culture_stats_attributes = cultureStats.map((stat, index) => ({
      ...stat,
      position: index + 1,
    }));
    const benefit_stats_attributes = benefitStats.map((stat, index) => ({
      ...stat,
      position: index + 1,
    }));
    const insights_attributes = insights.map((stat, index) => ({
      ...stat,
      position: index + 1,
    }));
    const body = {
      company: {
        ...rest,
        culture_stats_attributes,
        benefit_stats_attributes,
        insights_attributes,
        icon,
        banner: bannerFile,
      },
    };

    const formData = serialize(body, { indices: true });

    try {
      setIsLoading(true);
      const response = await apiInstance.put<CompanyResponse>(
        API_ROUTES.COMPANY_PROFILE,
        formData
      );
      mutateCompanyResponse(response, { revalidate: false });

      // fetch webpage on save changes
      // so that updated page is present
      // when view profile is clicked
      const domain =
        typeof window !== 'undefined'
          ? window.location.origin
          : process.env.NEXT_PUBLIC_SITE_URL;
      const publicPagePathname = `${domain}/companies/${company.slug}`;
      await fetch(publicPagePathname);
      notificationInstance.success({
        title: 'Success!',
        message: 'Company profile updated successfully.',
      });
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      notificationInstance.handleExceptionError(error);
    }
  };

  const companyCustomLinks = useMemo(
    () => parseArrayResponse<CustomLinksAttributes>(company?.custom_links),
    [company]
  );

  return {
    company,
    companyInsights,
    onCompanyUpdate,
    companyCultuteStat,
    companyBenefitStat,
    companyDashboard,
    mutateCompanyDashboard,
    similarOpportunities,
    similarEvents,
    isLoading,
    companyCustomLinks,
  };
};
